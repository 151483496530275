exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-appointment-jsx": () => import("./../../../src/templates/appointment.jsx" /* webpackChunkName: "component---src-templates-appointment-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-legal-notices-jsx": () => import("./../../../src/templates/legal-notices.jsx" /* webpackChunkName: "component---src-templates-legal-notices-jsx" */),
  "component---src-templates-specialties-jsx": () => import("./../../../src/templates/specialties.jsx" /* webpackChunkName: "component---src-templates-specialties-jsx" */)
}

